import * as styles from "./embed-button.module.scss"

import React, { useState } from 'react'

import InteractEmbed from "./utils/interact-embed"
import Modal from './modal'

const EmbedButton = ({ id, theme, disableCover, redirectHost, children }) => {
  const [open, setOpen] = useState()
  const btnTheme = theme || 'primary'

  return (
    <>
      <button className={ `${ styles.btn } ${ styles[btnTheme] }` } onClick={ () => setOpen(true) }>{ children }</button>
      { open ? (
        <Modal className={ styles.previewModal } isOpen={ true } onClose={ () => setOpen() }>
          <div className={ styles.content }>
            <InteractEmbed id={ id } disableCover={ disableCover } redirectHost={ redirectHost } />
          </div>
        </Modal>
      ) : null }
    </>
  )
}

export default EmbedButton