import React, { useEffect } from "react"

const WistiaEmbed = ({ hashedId, aspectRatio, aspectRatioPercentage, embedOptions }) => {
  let handle = null

  // Push embed video to queue
  if (typeof window !== 'undefined') {
    window._wq = window._wq || []
    window._wq.push({
      id: hashedId,
      options: embedOptions,
      onHasData: (video) => {
        handle = video
      }
    });
  }

  useEffect(() => {
    if (!document.getElementById("wistia_script")) {
      var test = document.createElement("script")
      test.src = `https://fast.wistia.com/embed/medias/${hashedId}.jsonp`
      test.async = true
      document.body.appendChild(test)
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      document.body.appendChild(wistiaScript)
    }
    // cleanup function
    return () => {
      handle && handle.remove()
    }
  })

  return (
    <div className="wistia_responsive_padding" style={{padding: `${ aspectRatioPercentage ? aspectRatioPercentage : (aspectRatio === '4:3' ? '66.5%' : '56.25%') } 0 0 0`, position: 'relative', overflow: 'hidden'}}>
      <div className="wistia_responsive_wrapper" style={{height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}}>
        <div className={`wistia_embed wistia_async_${hashedId} popover=true videoFoam=true`}style={{height: '100%', width: '100%'}}>&nbsp;</div>
      </div>
    </div>
  )
}

export default WistiaEmbed
